import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";

const Login = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
        m="20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        bgcolor={colors.primary[500]}
      >
           <Box
        width="100%"
        maxWidth="400px"
        bgcolor={colors.primary[600]}
        p="30px"
        borderRadius="10px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
      ></Box>
      <Typography
          variant="h4"
          color={colors.grey[100]}
          textAlign="center"
          marginBottom="20px"
        >
        Solstice Owner
        </Typography>

        <Typography
          variant="body1"
          color={colors.grey[300]}
          textAlign="center"
          marginBottom="30px"
        >
          Please login to your account
        </Typography>
        <form>
            <Box display="flex" flexDirection="column" gap="20px">
            <TextField
              fullWidth
              variant="outlined"
              label="Email Address"
              type="email"
              InputLabelProps={{ style: { color: colors.grey[200] } }}
              InputProps={{
                style: { color: colors.grey[100], borderColor: colors.grey[200] },
              }}
            />
              <TextField
              fullWidth
              variant="outlined"
              label="Password"
              type="password"
              InputLabelProps={{ style: { color: colors.grey[200] } }}
              InputProps={{
                style: { color: colors.grey[100], borderColor: colors.grey[200] },
              }}
            />
            <Box display="flex" justifyContent="center" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Login
              </Button>
            </Box>
               {/* <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: colors.secondary[500],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.secondary[400],
                },
                padding: "10px 0",
              }}
            >
              Login
            </Button> */}
            </Box>
        </form>
<br />
        <Typography
          variant="body1"
          color={colors.grey[300]}
          textAlign="center"
          marginBottom="30px"
        >
          Don't have an account?{" "}
          <Link to="/register" > 
           {/* style={{ color: colors.secondary[500], textDecoration: "underline" }} */}
            Sign up
          </Link>
        </Typography>

        {/* <Typography
          variant="body2"
          color={colors.grey[300]}
          textAlign="center"
          marginTop="20px"
        >
          Don't have an account?{" "}
          <Typography
            component="span"
            color={colors.secondary[500]}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Sign up
          </Typography>
        </Typography> */}




        </Box>
    )
}

export default Login;

// import React from "react";
// import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
// import { tokens } from "../../theme";

// const Login = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   return (
//     <Box
//       m="20px"
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       justifyContent="center"
//       minHeight="100vh"
//       bgcolor={colors.primary[500]}
//     >
//       <Box
//         width="100%"
//         maxWidth="400px"
//         bgcolor={colors.primary[600]}
//         p="30px"
//         borderRadius="10px"
//         boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
//       >
//         <Typography
//           variant="h4"
//           color={colors.grey[100]}
//           textAlign="center"
//           marginBottom="20px"
//         >
//           Welcome Back
//         </Typography>
//         <Typography
//           variant="body1"
//           color={colors.grey[300]}
//           textAlign="center"
//           marginBottom="30px"
//         >
//           Please login to your account
//         </Typography>
//         <form>
//           <Box display="flex" flexDirection="column" gap="20px">
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Email Address"
//               type="email"
//               InputLabelProps={{ style: { color: colors.grey[200] } }}
//               InputProps={{
//                 style: { color: colors.grey[100], borderColor: colors.grey[200] },
//               }}
//             />
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Password"
//               type="password"
//               InputLabelProps={{ style: { color: colors.grey[200] } }}
//               InputProps={{
//                 style: { color: colors.grey[100], borderColor: colors.grey[200] },
//               }}
//             />
//             <Button
//               fullWidth
//               variant="contained"
//               sx={{
//                 backgroundColor: colors.secondary[500],
//                 color: colors.grey[100],
//                 "&:hover": {
//                   backgroundColor: colors.secondary[400],
//                 },
//                 padding: "10px 0",
//               }}
//             >
//               Login
//             </Button>
//           </Box>
//         </form>
//         <Typography
//           variant="body2"
//           color={colors.grey[300]}
//           textAlign="center"
//           marginTop="20px"
//         >
//           Don't have an account?{" "}
//           <Typography
//             component="span"
//             color={colors.secondary[500]}
//             sx={{ cursor: "pointer", textDecoration: "underline" }}
//           >
//             Sign up
//           </Typography>
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default Login;
